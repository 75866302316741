import React from 'react';

function Main() {
  return (
    <main className="main-container">
      <section className="first-section">
        <div className="text-section">
          <h2>
            Showcase Your Jewelry with Stunning 
            <span style={{color: '#1DA1F2'}}> AI-Generated Model Photos</span> 
            – No Photoshoot Needed!
          </h2>
          <p>Upload your jewelry photo and instantly get high-quality shots on AI-generated models. Save time, money, and elevate your sales.</p>
          <a href="https://buy.stripe.com/eVa5nneyk1embXa4gh" target="_blank" rel="noopener noreferrer">
            <button className="start-button">Start Now ➔</button>
          </a>
          <p className="price-info">✨ $49 for 150 images per month</p> 
        </div>

        <div className="image-section">
          <img src="https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/Unbenannt-1-75.png" alt="Jewelry Model" />
        </div>
      </section>

      {/* How It Works Section */}
      <section className="second-section">
        <h2>How It Works</h2>
        <div className="tiles-container">
          <div className="tile">
            <h3>Upload Your Jewelry</h3>
            <p>Simply upload a photo of your jewelry.</p>
          </div>
          <div className="tile">
            <h3>Choose Your Model & Setting</h3>
            <p>Describe the model and background or choose from a selection of pre-made options to match your vision perfectly.</p>
          </div>
          <div className="tile">
            <h3>Generate Your Image</h3>
            <p>Get high-quality AI-generated photos.</p>
          </div>
        </div>
      </section>
      
        {/* <div>
        <h2 className="gallery-title">Describe Your Model & Bring Your Vision to Life</h2>
          <div class="gallery-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-23.png?alt=media&token=6d4dfcd0-a898-4e4e-8f59-0198dea0253e" alt="Bild 1" />
            <p>"woman with long brown hair and glowing skin"</p>
          </div>
          <div class="gallery-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-24.png?alt=media&token=12777b3a-f954-4945-ba34-e8a453d82014" alt="Bild 2" />
            <p>"black woman on the beach, windy"</p>
          </div>
          <div class="gallery-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-25.png?alt=media&token=fea5ea78-3e21-47cf-bb73-efe80f24071b" alt="Bild 3" />
            <p>"blonde woman in the desert, with wind blowing"</p>
          </div>
          <div class="gallery-item">
            <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-26.png?alt=media&token=1993e646-72de-486e-b6a1-ab109a28298f" alt="Bild 4" />
            <p>"woman with wavy brown hair and green eyes, in soft natural lighting."</p>
          </div>
        
        </div>
        </div>  */}
  
       {/* <h2 className="gallery-title">Explore Different Styles</h2> 
      <div className="gallery">
        <div className="gallery-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-28.png?alt=media&token=fe413e28-2635-413b-9518-f6886ed45ed2" alt="Bild 1" />
          <p>"woman with long brown hair and glowing skin"</p>
        </div>
        <div className="gallery-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-32.png?alt=media&token=77dae839-3700-41f8-b4da-e20d1e9a3af0" alt="Bild 2" />
          <p>"black woman on the beach, windy"</p>
        </div>
        <div className="gallery-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-33.png?alt=media&token=ba6b263c-1939-451e-9481-f99b897c6ed1" alt="Bild 3" />
          <p>"blonde woman in the desert, with wind blowing"</p>
        </div>
        <div className="gallery-item">
          <img src="https://firebasestorage.googleapis.com/v0/b/jewlai.appspot.com/o/Unbenannt-2-29.png?alt=media&token=b2bf10a3-4360-4719-ba6b-b49bff8dfe67" alt="Bild 4" />
          <p>"woman with wavy brown hair and green eyes, in soft natural lighting."</p>
        </div>
        
      </div> */}
    </main>
  );
}

export default Main;