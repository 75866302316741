import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Tryon from './tryon'; // Import the Tryon component
import CoordinateSystem from './CoordinateSystem'; // Importiere die CoordinateSystem-Komponente
import Main from './components/Main';
import './App.css';

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <Header />
                <Navigation />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/tryon" element={<Tryon />} /> {/* Direct access to Tryon */}
                    <Route path="/tryon/user/:userId/image/:docRefId" element={<Tryon />} />
                </Routes>
                <Footer />
            </div>
        </BrowserRouter>
    );
}

export default App;
