// src/tryon.js
import React, { useState, useEffect, createContext, useContext } from 'react';
import { uploadToR2 } from './uploadToR2'; // Import the uploadToR2 function
import { auth } from './firebase'; // Import the authentication instance
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; // Import Google Auth functions
import { serverTimestamp, onSnapshot, query, orderBy, collection } from "firebase/firestore"; // Import Firestore functions
import { firestore, addDoc } from "./firebase"; // Import Firestore functions
import CoordinateSystem from './CoordinateSystem'; // Importiere die neue Komponente

// Create a context for image processing
const ImageProcessingContext = createContext();

const Tryon = () => {
    const [image, setImage] = useState(null);
    const [error, setError] = useState('');
    const [outputUrl, setOutputUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null); // State to hold the uploaded file
    const [successMessage, setSuccessMessage] = useState(''); // State to hold the success message
    const [selectedImage, setSelectedImage] = useState(null); // State to hold the selected image
    const [coordinateImages, setCoordinateImages] = useState([]); // Zustand für die Bilder im Koordinatensystem
    const [imageUrl, setImageUrl] = useState(''); // Zustand für die URL des neuen Bildes
    const [choosedModelImage, setChoosedModelImage] = useState(null); // Zustand für das ausgewählte Modellbild
    const [images, setImages] = useState([]); // Zustand für die abgerufenen Bilder
    const [imageUrlRb, setImageUrlRb] = useState(''); // Zustand für die imageUrlRb
    const [newModelImage, setNewModelImage] = useState(null); // Zustand für das neue Modellbild
    const [combinedImage, setCombinedImage] = useState(null); // Zustand für das kombinierte Bild

    // Funktion zum Abrufen der Bilder aus Firestore
    const fetchImages = () => {
        const user = auth.currentUser; // Hole den aktuellen Benutzer
        console.log("Current user:", user); // Debugging-Log
        if (!user) {
            console.log("No user is logged in."); // Debugging-Log
            return;
        }

        const imagesRef = collection(firestore, "users", user.uid, "images");
        const q = query(imagesRef, orderBy("createdAt", "desc"));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const imagesData = [];
            querySnapshot.forEach((doc) => {
                const imageData = doc.data();
                const imageId = doc.id;
                imagesData.push({ id: imageId, ...imageData });
            });
            console.log("Fetched images:", imagesData); // Debugging-Log
            setImages(imagesData); // Setze die abgerufenen Bilder

            // Setze das ausgewählte Modellbild, wenn es existiert
            const choosedImage = imagesData.find(image => image.choosedModelImage);
            console.log("Choosed image:", choosedImage); // Debugging-Log
            if (choosedImage) {
                setChoosedModelImage(choosedImage.choosedModelImage); // Setze das ausgewählte Modellbild
            } else {
                console.log("No choosed model image found."); // Debugging-Log
            }

            // Setze die imageUrlRb, wenn sie existiert und noch nicht gesetzt ist
            const imageUrlRb = imagesData.find(image => image.imageUrlRb);
            if (imageUrlRb) {
                setImageUrlRb(imageUrlRb.imageUrlRb); // Setze die imageUrlRb
                console.log("Image URL for RB:", imageUrlRb.imageUrlRb); // Debugging-Log
            } else {
                console.log("No imageUrlRb found."); // Debugging-Log
            }
        });

        return () => unsubscribe(); // Aufräumen des Listeners
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                console.log("User is logged in:", user.uid);
                fetchImages(); // Rufe die Funktion zum Abrufen der Bilder auf, wenn der Benutzer angemeldet ist
            } else {
                console.log("No user is logged in.");
            }
        });

        return () => unsubscribe(); // Aufräumen des Listeners
    }, []);

    useEffect(() => {
        if (newModelImage) {
            // Überprüfen, ob das Bild bereits im Array vorhanden ist
            const imageIndex = images.findIndex(image => image.url === newModelImage);
            if (imageIndex !== -1) {
                // Wenn das Bild bereits vorhanden ist, aktualisiere nur die Position
                setImages(prevImages => {
                    const updatedImages = [...prevImages];
                    updatedImages[imageIndex] = {
                        ...updatedImages[imageIndex],
                        x: 50, // Neue Position, wenn nötig
                        y: 50, // Neue Position, wenn nötig
                    };
                    return updatedImages;
                });
            } else {
                // Wenn das Bild nicht vorhanden ist, ersetze das erste Bild (oder füge es hinzu, wenn das Array leer ist)
                setImages((prevImages) => [
                    { url: newModelImage, x: 50, y: 50, width: 100, height: 100 }, // Standardposition und -größe
                    ...prevImages.filter(image => image.url !== newModelImage), // Entferne das alte Bild, falls vorhanden
                ]);
            }
        }
    }, [newModelImage]); // Abhängigkeit: Wenn newModelImage sich ändert

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            const selectedFile = files[0];
            if (selectedFile.type.startsWith('image/')) {
                setImage(URL.createObjectURL(selectedFile)); // Zeige das Bild an
                setError('');
                setFile(selectedFile); // Speichere die Datei für die spätere Verwendung
            } else {
                setError('Please upload a valid image file.');
            }
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Verhindere das Standardverhalten
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider(); // Erstelle einen Google Auth Provider
        try {
            const result = await signInWithPopup(auth, provider); // Melde den Benutzer an
            const user = result.user; // Hole den Benutzer
            console.log('User signed in:', user.email); // Logge die E-Mail-Adresse des Benutzers
        } catch (error) {
            console.error("Error during Google sign-in:", error.message); // Fehlerbehandlung
            setError(error.message); // Setze die Fehlermeldung
        }
    };

    const handleGoClick = async () => {
        console.log('handleGoClick called'); // Debugging-Log
        const user = auth.currentUser; // Hole den aktuellen Benutzer
        if (!user) {
            handleGoogleSignIn(); // Wenn der Benutzer nicht angemeldet ist, melde ihn an
        } else {
            console.log('Current user ID:', user.uid); // Überprüfe die Benutzer-ID

            if (file) {
                try {
                    const link = await uploadToR2(file); // Lade die Datei hoch und erhalte den Link
                    console.log('Uploaded image URL:', link); // Logge die URL
                    setSuccessMessage('Image uploaded and Firestore entry created successfully!'); // Setze die Erfolgsmeldung
                    setImage(null); // Leere das Bild nach erfolgreichem Upload
                    setFile(null); // Leere den Dateizustand

                    // Hier wird die URL des ausgewählten Bildes korrekt gesetzt
                    const imageData = {
                        userId: user.uid,
                        createdAt: serverTimestamp(),
                        status: "created",
                        creditCost: 1, // Assuming a default credit cost
                        imageUrl: link, // Store the image URL
                        choosedModelImage: selectedImage // Füge die URL des ausgewählten Bildes hinzu
                    };

                    const imagesCollectionRef = collection(firestore, "users", user.uid, "images");
                    const docRef = await addDoc(imagesCollectionRef, imageData);
                    console.log('Image entry created in Firestore:', docRef.id);

                    // Setze die outputUrl mit userId und docRef.id
                    const newOutputUrl = `/tryon/user/${user.uid}/image/${docRef.id}`;

                    // Lösche die bestehende URL und setze die neue URL
                    window.history.replaceState({}, '', newOutputUrl); // Ändere die URL im Browser

                    // Setze die outputUrl
                    setOutputUrl(newOutputUrl); // Setze die outputUrl

                    // Leere den Zustand, um das Drag-and-Drop erneut zu ermöglichen
                    setImage(null); // Leere das Bild nach dem Upload
                    setFile(null); // Leere den Dateizustand

                } catch (error) {
                    console.error('Upload failed:', error);
                    setError('An error occurred while uploading the image: ' + error.message);
                }
            } else {
                setError('Please upload an image first.');
            }
        }
    };

    const handleImageSelect = (imageUrl) => {
        // Setze das ausgewählte Modellbild direkt aus dem Frontend
        setChoosedModelImage(imageUrl);
        setNewModelImage(imageUrl); // Setze das newModelImage
        console.log("New choosed model image set:", imageUrl); // Debugging-Log
    };

    const IMAGE_URL_1 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/clarityai-fJCxH0c5hyMBtMnGPHuGGPXOMto2-rEaiWVl5GZPNI8DSoFkS.jpg";
    const IMAGE_URL_2 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/clarityai-fJCxH0c5hyMBtMnGPHuGGPXOMto2-Fcx0sSC7es9qvw2uguMw.jpg";
    const IMAGE_URL_3 = "https://pub-1b0ab9fa2a8742a59a5735f35984d229.r2.dev/Media/clarityai-fJCxH0c5hyMBtMnGPHuGGPXOMto2-DW2yyaRvk7GAMVBBUKvf.jpg";

    const handleChooseModelImage = (newModelImage) => {
        // Setze nur das neue Modellbild
        setChoosedModelImage(newModelImage);
        setNewModelImage(newModelImage); // Setze das newModelImage
        console.log("New choosed model image set:", newModelImage);
    };

    const handleCombineImages = async () => {
        if (newModelImage && image) {
            try {
                const response = await fetch('/your-combine-endpoint', { // Ersetze mit deinem tatsächlichen Endpunkt
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        newModelImageUrl: newModelImage,
                        imageUrlRb: image, // Hier die URL des Bildes, das du hochgeladen hast
                    }),
                });

                const data = await response.json();
                if (data.image) {
                    // Setze das kombinierte Bild in den Zustand
                    setCombinedImage(data.image);
                }
            } catch (error) {
                console.error('Error combining images:', error);
            }
        } else {
            console.error('Please select both model and necklace images.');
        }
    };

    // Funktion zum Abrufen des kombinierten Bildes
    const fetchCombinedImage = async () => {
        const response = await fetch('/your-endpoint'); // Ersetze mit deinem tatsächlichen Endpunkt
        const data = await response.json();
        setCombinedImage(data.combined_image); // Setze den Base64-String in den Zustand
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
            {/* Linke Spalte: Auswahl des Modells */}
            <div style={{ flex: 1, marginRight: '20px' }}>
                <h2>1. Choose Model Image</h2>
                <div className="image-selector" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                    <img 
                        src={IMAGE_URL_1} 
                        alt="Model 1" 
                        className={`selectable ${selectedImage === IMAGE_URL_1 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_1)} 
                        style={{ 
                            filter: selectedImage && selectedImage !== IMAGE_URL_1 ? 'grayscale(100%)' : 'none', 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto' 
                        }} 
                    />
                    <img 
                        src={IMAGE_URL_2} 
                        alt="Model 2" 
                        className={`selectable ${selectedImage === IMAGE_URL_2 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_2)} 
                        style={{ 
                            filter: selectedImage && selectedImage !== IMAGE_URL_2 ? 'grayscale(100%)' : 'none', 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto' 
                        }} 
                    />
                    <img 
                        src={IMAGE_URL_3} 
                        alt="Model 3" 
                        className={`selectable ${selectedImage === IMAGE_URL_3 ? 'selected' : ''}`} 
                        onClick={() => handleImageSelect(IMAGE_URL_3)} 
                        style={{ 
                            filter: selectedImage && selectedImage !== IMAGE_URL_3 ? 'grayscale(100%)' : 'none', 
                            cursor: 'pointer', 
                            width: '30%', 
                            height: 'auto' 
                        }} 
                    />
                </div>

                <h2 style={{ marginTop: '20px' }}>2. Drop Image of Necklace</h2>
                <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    style={{
                        border: '2px dashed #ccc',
                        borderRadius: '20px',
                        width: '100%',
                        height: '200px',
                        textAlign: 'center',
                        padding: '20px',
                        position: 'relative',
                        boxSizing: 'border-box',
                    }}
                >
                    <p>Drag your image here</p>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {image && (
                        <img 
                            src={image} 
                            alt="Preview" 
                            style={{ 
                                display: 'block', 
                                margin: '0 auto', 
                                maxWidth: '100%', 
                                maxHeight: '100%', 
                                objectFit: 'contain' 
                            }} 
                        />
                    )}
                    <button onClick={handleGoClick} style={{ marginTop: '20px' }}>
                        Upload Image
                    </button>
                </div>
                {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                
            </div>

            {/* Rechte Spalte: Koordinatensystem */}
            <div style={{ flex: 1 }}>
                <h2>Coordinate System</h2>
                <CoordinateSystem newModelImage={newModelImage} imageUrlRb={imageUrlRb} /> {/* Bild an die Komponente übergeben */}
            </div>

            {combinedImage && (
                <div>
                    <h2>Combined Image Preview</h2>
                    <img 
                        src={combinedImage} 
                        alt="Combined Preview" 
                        style={{ 
                            display: 'block', 
                            margin: '0 auto', 
                            maxWidth: '100%', 
                            maxHeight: '100%', 
                            objectFit: 'contain' 
                        }} 
                    />
                </div>
            )}
        </div>
    );
};

export default Tryon;
